import React from "react";
import { HeaderPage } from "../portfolio/HeaderPage";
import { TrendHamburger } from "../trendLab/TrendHamburger";
import { TrendButtons } from "../trendLab/TrendButtons";
import { Typography } from "@mui/material";
import SupportImg1 from "../../assets/images/SupportImg1.png";
import { SupportHamburger } from "./SupportHamburger";
import { useNavigate } from "react-router";
import { SupportHeader } from "./SupportHeader";

export const SupportIntro = () => {
  const menu2 = [
    { id: 0, title: "Home" },
    { id: 1, title: "Portfolio" },
    { id: 2, title: "TREND Lab" },
  ];
  const trendHeadbtn = [
    {
      id: 0,
      title: "Lets Connect",
    },
  ];
  const supportBtn = [{ id: 0, title: "Contribute" }];

  return (
    <>
      <div className="z-0 supportBgImg1">
        <div
          className="hidden lg:block"
          style={{ position: "", top: 0, zIndex: 50 }}
        >
          <SupportHeader menu1={menu2} headbutton1={trendHeadbtn} />
        </div>
        <div className="lg:hidden ">
          <SupportHamburger />
        </div>
        <div
          className="flex flex-col-reverse items-center justify-between mx-auto text-white 2xl:flex-row "
          style={{ maxWidth: "80%" }}
        >
          <div className="mx-10">
            <Typography
              className="mt-20 2xl:pt-20"
              style={{
                fontSize: 48,
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              Promote Brain
              <br /> Resilience: Rally as a<br /> TREND Ambassador
            </Typography>

            <Typography
              className="my-5 "
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Montserrat",
              }}
            >
              Join us in our mission to unlock the secrets of brain health. Your
              support drives groundbreaking research at TREND LAB, helping us
              find answers to complex neurological challenges. Together, we can
              make a difference. Become a TREND
              <br />
              Backer today and be part of the solution.
            </Typography>
            <div className="2xl:mb-48">
              <TrendButtons Tbutton1={supportBtn} />
            </div>
          </div>
          <div className="w-full h-[378px]">
            <img className="" src={SupportImg1} />
          </div>
        </div>
      </div>
    </>
  );
};
