import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import { ArrowUpwardSharp } from "@mui/icons-material";

export const ConsultancyForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("FormData: ", formData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mail`,
        formData
      );
      if (response.status === 200) {
        alert("Message sent successfully!");
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const scrollToTop = () => {
    document.getElementById("hero").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        id="Lets Connect"
        className="flex items-center justify-center px-10 py-20 h-1/2"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <div className="flex flex-col pt-5 md:flex-row ">
            <div className="flex flex-col md:mr-8 ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Full Name
              </label>
              <TextField
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
            <div className="flex flex-col ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Phone Number
              </label>
              <TextField
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
          </div>
          <div className="flex flex-col pt-5 md:flex-row">
            <div className="flex flex-col md:mr-8 ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Email Address
              </label>
              <TextField
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
            <div className="flex flex-col ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Subject
              </label>
              <TextField
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
          </div>
          <div className="flex flex-col py-3 ">
            <label
              className="py-3 text-white "
              style={{ fontSize: 16, fontWeight: 600 }}
            >
              Your Message
            </label>
            <TextField
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="bg-white rounded-lg w-80 md:w-full"
            />
          </div>
          <div className="flex items-end justify-end mt-3 ">
            <Button
              type="submit"
              className="w-40 text-white border-2 border-solid rounded-lg text-decoration-none"
              style={{
                fontSize: 14,
                backgroundColor: "#3778A5",
                textTransform: "none",
              }}
            >
              Send Message
            </Button>
          </div>
        </Box>
      </div>
      <div>
        {/* Up arrow to scroll to top */}
        <div className="flex justify-end mb-10 mr-10">
          <IconButton
            onClick={scrollToTop}
            style={{ backgroundColor: "#35558C" }}
          >
            <ArrowUpwardSharp fontSize="small" style={{ color: "white" }} />
          </IconButton>
        </div>
      </div>
    </>
  );
};
