import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";
import GrayArrowRight from "../../assets/images/GrayArrowRight.png";

export const TeamLoop2 = ({ Loop2, isLastItem }) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Extract image, sub-heading, and descriptions from Loop2
  const image = Loop2.find((item) => item.key === "image");
  const subHeading = Loop2.find((item) => item.key === "sub-heading");
  const descriptions = Loop2.filter((item) => item.key === "desc");

  // Find the smallest and largest description_id
  const description1 =
    descriptions.length > 0
      ? descriptions.reduce((min, desc) =>
          desc.description_id < min.description_id ? desc : min
        )
      : null;
  const description2 =
    descriptions.length > 0
      ? descriptions.reduce((max, desc) =>
          desc.description_id > max.description_id ? desc : max
        )
      : null;

  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
      }}
      className={`${isLastItem ? "TrendTreatBg bg-transparent" : ""}`}
    >
      <div
        className="flex justify-between"
        data-aos="fade-right"
        data-aos-delay="500"
      >
        {/* Image 1 */}
        <div
          className="flex justify-end hidden lg:block"
          style={{ flex: 1, background: "#EBEEF3" }}
        >
          {image && (
            <img
              src={convertImagePathToUrl(image.image_url)}
              alt="Team Image 1"
              className="h-full mr-auto"
            />
          )}
        </div>

        {/* Content */}
        <div
          className="h-[700px] flex flex-col items-center"
          style={{ flex: 2, background: "#EBEEF3" }}
        >
          <div
            className="py-[10rem] mx-20 lg:mx-0"
            style={{ maxWidth: "580px" }}
          >
            {/* Sub-heading */}
            {subHeading && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {subHeading.sub_heading}
              </Typography>
            )}
            {/* Description 1 (Smallest ID) */}
            {description1 && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {description1.description}
              </Typography>
            )}
            {/* Description 2 (Largest ID) */}
            {description2 && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {description2.description}
              </Typography>
            )}
          </div>
        </div>

        {/* Image 2 */}
        <div className="hidden lg:block" style={{ flex: 1 }}>
          {image && (
            <img
              src={GrayArrowRight}
              alt="Team Image 2"
              className="h-[700px] mr-auto leftArrowStyle"
            />
          )}
        </div>
      </div>
    </div>
  );
};
