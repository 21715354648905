import React, { useEffect, useState } from "react";
import { Scientist } from "./Scientist";
import { Introduction } from "./Introduction";
import { Educator } from "./Educator";
import { Humantarian } from "./Humantarian";
import { Consultancy } from "./Consultancy";
import { BuzzCrousal } from "./BuzzCrousal";
import { Footer } from "../Footer";
import { getApiData } from "../../axios";

export const HomePage = () => {
  const [hero, setHero] = useState([]);
  const [about, setAbout] = useState([]);
  const [scientist, setScientist] = useState([]);
  const [brainality, setBrainality] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [publications, setPublications] = useState([]);
  const [educators, setEducators] = useState([]);
  const [advocacy, setAdvocacy] = useState([]);
  const [humantarian, setHumantarian] = useState([]);
  const [author, setAuthor] = useState([]);
  const [buzz, setBuzz] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const parseSectionDetails = (details) => {
    return details.map((detail) => {
      let singleArray = [
        ...detail.sub_headings.map((subHeading) => ({
          ...subHeading,
          key: "sub-heading",
        })),
        ...detail.descriptions.map((desc) => ({ ...desc, key: "desc" })),
        ...detail.assets.map((asset) => ({ ...asset, key: "asset" })),
      ];

      let buttonPositions = [];
      detail.buttons.forEach((btt) => {
        if (!buttonPositions.includes(btt.button_position))
          buttonPositions.push(btt.button_position);
      });

      let buttonPositionObjects = [];
      buttonPositions.forEach((position) => {
        buttonPositionObjects.push({
          key: "btt",
          button_position: position,
          childButtons: detail.buttons.filter(
            (button) => button.button_position === position
          ),
        });
      });

      singleArray = [...singleArray, ...buttonPositionObjects];

      singleArray = singleArray.sort((a, b) => {
        let positionA;
        let positionB;

        if (a.key === "sub-heading") positionA = a.sub_heading_position;
        if (a.key === "desc") positionA = a.description_position;
        if (a.key === "btt") positionA = a.button_position;

        if (b.key === "sub-heading") positionB = b.sub_heading_position;
        if (b.key === "desc") positionB = b.description_position;
        if (b.key === "btt") positionB = b.button_position;

        if (positionA === positionB) return 0;

        return positionA > positionB ? 1 : -1;
      });

      detail.singleArray = singleArray;
      return detail;
    });
  };

  const getData = async () => {
    try {
      const res = await getApiData();

      if (res.status >= 200 && res.status < 300) {
        res.data.sections.forEach((section) => {
          switch (section.section_title) {
            case "Hero":
              section.details = parseSectionDetails(section.details);
              setHero(section);
              break;
            case "About":
              setAbout(section);
              section.details = parseSectionDetails(section.details);
              break;
            case "The Scientist":
              section.details = parseSectionDetails(section.details);
              setScientist(section);
              break;
            case "Key Publications":
              setPublications(section);
              break;
            case "The Educator":
              section.details = parseSectionDetails(section.details);
              setEducators(section);
              break;
            case "Public Education & Advocacy":
              section.details = parseSectionDetails(section.details);
              setAdvocacy(section);
              break;
            case "The Humanitarian":
              section.details = parseSectionDetails(section.details);
              setHumantarian(section);
              break;
            case "The Author":
              section.details = parseSectionDetails(section.details);
              setAuthor(section);
              break;
            case "Brainality":
              section.details = parseSectionDetails(section.details);
              setBrainality(section);
              break;
            case "Birthday":
              section.details = parseSectionDetails(section.details);
              setBirthday(section);
              break;
            case "The Buzz":
              section.details = parseSectionDetails(section.details);
              setBuzz(section);
              break;
            default:
              console.log(`Unknown section: ${section.section_title}`);
          }
        });
        setLoading(false); // Data has finished loading
      } else {
        console.error("Failed to fetch data:", res.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false); // Ensure we stop loading even if there's an error
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Introduction hero={hero} about={about} />
          <Scientist
            scientist={scientist}
            publications={publications}
            brainality={brainality}
          />
          <Educator educators={educators} advocacy={advocacy} />
          <Humantarian
            humantarian={humantarian}
            birthday={birthday}
            author={author}
          />
          <BuzzCrousal buzz={buzz} />
          <Consultancy />
          <Footer color="#001728" />
        </>
      )}
    </>
  );
};
