import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../landingPage/PageHeader";
import { PageFooter } from "../landingPage/PageFooter";
import { Typography } from "@mui/material";
import { Footer } from "../Footer";

export const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="z-0 loginImg">
        <PageHeader />
        <div
          style={{ height: "100vh" }}
          className="flex flex-col items-center justify-center pb-20 mt-10 2xl:mt-20 md:flex-row imghide"
        >
          <div className="img1">
            <a
              className="cursor-pointer"
              id="portfolio"
              onClick={() => handleNavigation("/portfolio")}
            >
              <div className="flex flex-col h-full ml-20">
                <div className="h-1/2" />
                <div className="h-1/2 lg:pt-10">
                  <Typography
                    className="text-white"
                    style={{ fontFamily: "Montserrat", fontSize: 20 }}
                  >
                    Dr. Ali Jawaid
                  </Typography>
                  <Typography
                    className="text-white"
                    style={{ fontFamily: "Montserrat", fontSize: 15 }}
                  >
                    Neuroscience
                    <br /> Researcher & Medical
                    <br /> Doctor
                  </Typography>
                </div>
              </div>
            </a>
          </div>
          <div className="img2">
            <a
              className="cursor-pointer"
              id="trendlab"
              onClick={() => handleNavigation("/trendlab")}
            >
              <div className="flex flex-col h-full mr-20">
                <div className="h-1/2" />
                <div className="h-1/2 lg:pt-10">
                  <Typography
                    className="text-white"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 20,
                      textAlign: "right",
                    }}
                  >
                    TREND Lab
                  </Typography>
                  <Typography
                    className="text-white"
                    style={{
                      fontFamily: "Montserrat",
                      textAlign: "right",
                      fontSize: 15,
                    }}
                  >
                    Laboratory of
                    <br /> Translational Research in <br /> Neuropsychiatric{" "}
                    <br /> Disorders
                  </Typography>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer color="#0C1C2B" />
    </div>
  );
};
