import { Typography, IconButton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/buzz.css";
import { ArrowBackSharp, ArrowForwardSharp } from "@mui/icons-material";

import { convertImagePathToUrl } from "../../utils";

// Custom Previous Arrow Component
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-prev"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "30%", // Center vertically
        left: "-10px", // Distance from the left side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackSharp />
    </IconButton>
  );
};

// Custom Next Arrow Component
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-next"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "30%", // Center vertically
        right: "-10px", // Distance from the right side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowForwardSharp />
    </IconButton>
  );
};

export const BuzzCrousal = ({ buzz }) => {
  var settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow
    nextArrow: <CustomNextArrow />, // Custom Next Arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="The Buzz" className="py-20 bg-[#EBEEF3]">
      <div className="flex flex-col items-center justify-center px-10 pt-10">
        <Typography
          style={{
            fontSize: 36,
            fontFamily: "Montserrat",
            fontWeight: 400,
          }}
        >
          {buzz?.section_heading}
        </Typography>
        <Typography
          className="px-10 py-5"
          style={{
            fontSize: 14,
            fontFamily: "Montserrat",
          }}
        >
          {buzz?.section_description}
        </Typography>
      </div>

      <div>
        <div className="w-10/12 py-10 mx-auto h-10/12">
          <Slider {...settings}>
            {buzz?.details?.map((detail, index) =>
              detail?.singleArray?.map((det, ind) => (
                <div className="flex justify-center" key={`${index}-${ind}`}>
                  <div className="w-8/12">
                    <img
                      src={convertImagePathToUrl(det.sub_heading_bg_image)}
                      className="w-full"
                    />
                    {det?.key === "sub-heading" && (
                      <>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                          }}
                          className="pt-5"
                        >
                          {det?.sub_heading}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            color: "#7A5CAA",
                            fontWeight: 700,
                            fontFamily: "Montserrat",
                          }}
                          className="pb-5"
                        >
                          <a
                            href={det?.sub_heading_url || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </a>
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};
