import React from "react";
import Slider from "react-slick";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/portfolio.css";
import "../../assets/css/edu.css";
import { convertImagePathToUrl } from "../../utils";
import { ArrowBackSharp, ArrowForwardSharp } from "@mui/icons-material";

// Custom Previous Arrow Component
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-prev"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "40%", // Center vertically
        left: "-50px", // Distance from the left side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackSharp />
    </IconButton>
  );
};

// Custom Next Arrow Component
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-next"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "40%", // Center vertically
        right: "-50px", // Distance from the right side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowForwardSharp />
    </IconButton>
  );
};

export const EduCrousal = ({ advocacy }) => {
  var settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow
    nextArrow: <CustomNextArrow />, // Custom Next Arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="flex flex-col items-center">
        <Typography
          className="text-white "
          style={{
            fontSize: 24,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          {advocacy?.section_heading}
        </Typography>
        <Typography
          className="pt-5 mx-5 text-center text-white"
          style={{
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Montserrat",
          }}
        >
          {advocacy?.section_description}
        </Typography>
      </div>
      <div>
        <div className="w-10/12 py-20 mx-auto h-10/12">
          <Slider {...settings}>
            {advocacy?.details?.[0]?.sub_headings?.length > 0 ? (
              advocacy.details[0].sub_headings.map((subHeading, index) => (
                <a
                  key={subHeading.sub_heading_id}
                  href={subHeading.sub_heading_url || "#"}
                  target="_blank"
                  className={`relative flex items-center justify-center overflow-hidden text-center zoom-in ${
                    index % 2 !== 0 ? "pt-20" : ""
                  }`}
                >
                  <img
                    src={convertImagePathToUrl(subHeading.sub_heading_bg_image)}
                    alt="image"
                    className="object-cover w-[475px] h-[475px]"
                    style={{
                      visibility: "visible",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      margin: "45% 30%",
                    }}
                    className="absolute text-[10px] text-blue-700 lg:text-[10px] md:text-[10px] 2xl:text-[14px]"
                  >
                    {subHeading.sub_heading}
                  </Typography>
                </a>
              ))
            ) : (
              <p>No sub-headings available</p>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};
