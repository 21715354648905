import { Typography, Button } from "@mui/material";
import React from "react";

export const PortfolioButtons = ({ Pbutton1 }) => {
  const handleClick = (value) => {
    if (value.title === "Support Us") {
      window.location.href = value.action; // Redirect to the backend route
    } else {
      const element = document.getElementById(value.action);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="flex flex-col items-center xl:flex-row">
      {Pbutton1.map((value) => (
        <Button
          key={value.title} // Added key prop for list rendering
          onClick={() => handleClick(value)}
          style={{
            borderColor: "#FFFFFF",
            backgroundColor:
              value.title === "Support Us" ? "#3778A5" : "transparent", // Conditional background color
          }}
          className="flex justify-center h-12 mt-5 mr-5 text-white border-2 border-solid rounded-lg w-60 2xl:w-64 2xl:h-12"
        >
          <Typography className="text-[14px]" style={{ textTransform: "none" }}>
            {value.title}
          </Typography>
        </Button>
      ))}
    </div>
  );
};
