import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { ScientistBtn } from "./ScientistBtn";
import { KeyPublications } from "./KeyPublications";
import AOS from "aos";
import "aos/dist/aos.css";

import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.png";
import ScientistImg2 from "../../assets/images/ScientistImg2.png";
import ScientistImg3 from "../../assets/images/ScientistImg3.png";
import ScientistImg4 from "../../assets/images/ScientistImg4.png";
import "../../assets/css/scientist.css";

export const Scientist = ({ scientist, brainality, publications }) => {
  const { details = [] } = scientist || {};
  const detail = details.length > 0 ? details[0] : {};
  const { sub_headings = [], descriptions = [], buttons = [] } = detail;

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 500,
    });
  }, []);

  if (scientist?.length === 0 && brainality?.length === 0) return;

  return (
    <div>
      <div id="The Scientist">
        <div
          style={{
            backgroundColor: "#EBEEF3",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <div className="h-[700px] flex justify-between" data-aos="fade-left">
            <div
              className="flex justify-end hidden lg:block"
              style={{ flex: 1 }}
            >
              <img
                src={WhiteArrowLeft}
                className="h-full ml-auto leftArrowStyle"
                alt="White Arrow Left"
              />
            </div>
            <div
              className="flex flex-col items-center py-10 bg-white"
              style={{ flex: 2 }}
            >
              <div
                className="py-10 mx-20 lg:mx-0"
                style={{ maxWidth: "580px" }}
              >
                <Typography
                  style={{
                    fontSize: 36,
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                  }}
                >
                  {scientist?.section_heading}
                </Typography>
                {scientist?.details?.map((detail, index) => (
                  <React.Fragment key={index}>
                    {detail?.singleArray?.map((det, ind) => (
                      <React.Fragment key={ind}>
                        {det?.key === "sub-heading" && (
                          <Typography
                            className="mt-5"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {det?.sub_heading}
                          </Typography>
                        )}
                        {det?.key === "btt" && (
                          <div className="flex justify-start pb-10">
                            <ScientistBtn Sbutton1={det?.childButtons} />
                          </div>
                        )}
                        {det?.key === "desc" && (
                          <Typography
                            className="mx-auto my-5"
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {det?.description}
                          </Typography>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="hidden bg-white lg:block" style={{ flex: 1 }}>
              <img
                src={ScientistImg2}
                className="h-full ml-auto leftArrowStyle"
                alt="Scientist Image 2"
              />
            </div>
          </div>
        </div>
      </div>

      {/* BRAINALITY */}
      <div
        className="z-0 portfolioImg4"
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div
          data-aos="fade-right"
          className="h-[700px] z-0 flex justify-between portfolioImg4"
        >
          <div
            className="flex justify-end hidden lg:block"
            style={{ flex: 1, background: "#EBEEF3" }}
          >
            <img
              src={ScientistImg4}
              className="h-full mr-auto leftArrowStyle"
              style={{ background: "#EBEEF3" }}
              alt="Scientist Image 4"
            />
          </div>
          <div
            className="flex flex-col items-center"
            style={{ flex: 2, background: "#EBEEF3" }}
          >
            <div className="py-40 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
              {brainality?.details?.map((detail, index) => (
                <React.Fragment key={index}>
                  {detail?.singleArray?.map((det, ind) => (
                    <React.Fragment key={ind}>
                      {det?.key === "sub-heading" && (
                        <Typography
                          className="mt-5"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.sub_heading}
                        </Typography>
                      )}
                      {det?.key === "btt" && (
                        <div className="flex justify-start pb-10">
                          <ScientistBtn Sbutton1={det?.childButtons} />
                        </div>
                      )}
                      {det?.key === "desc" && (
                        <Typography
                          className="mx-auto my-5"
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.description}
                        </Typography>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="hidden lg:block" style={{ flex: 1 }}>
            <img
              src={ScientistImg3}
              className="h-full mr-auto leftArrowStyle"
              alt="Scientist Image 3"
            />
          </div>
        </div>
        <KeyPublications publications={publications} />
      </div>
    </div>
  );
};
