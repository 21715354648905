import { Typography } from "@mui/material";
import React from "react";

export const Trends = ({ trendTrends }) => {
  if (!trendTrends || !trendTrends[0] || !trendTrends[0].descriptions) {
    return null; // or a loading indicator if data is being fetched
  }

  const descriptions = trendTrends[0].descriptions;

  return (
    <div>
      <div id="TREND Trends">
        <Typography
          className="flex justify-center text-white xl:py-32"
          style={{
            fontSize: 36,
            fontWeight: 400,
            fontFamily: "Montserrat",
          }}
        >
          TREND Trends
        </Typography>
      </div>

      <div
        className="flex flex-col justify-center mx-auto xl:flex-row"
        style={{ maxWidth: "80%" }}
      >
        {/* First Column */}
        <div className="text-white xl:w-1/2">
          <ul className="pl-5 list-disc">
            {descriptions
              .slice(0, Math.ceil(descriptions.length / 2))
              .map((description, index) => (
                <li
                  key={description.description_id}
                  className={index > 0 ? "mt-10" : ""}
                >
                  <Typography
                    style={{ fontSize: 14, fontFamily: "Montserrat" }}
                  >
                    {description.description}
                  </Typography>
                  <Typography
                    className="mt-2 text-blue-300"
                    style={{ fontSize: 14, fontFamily: "Montserrat" }}
                  >
                    <u>Read More Here</u>
                  </Typography>
                </li>
              ))}
          </ul>
        </div>

        {/* Second Column */}
        <div className="mt-10 text-white xl:w-1/2 xl:mt-0 xl:ml-10">
          <ul className="pl-5 list-disc">
            {descriptions
              .slice(Math.ceil(descriptions.length / 2))
              .map((description, index) => (
                <li
                  key={description.description_id}
                  className={index > 0 ? "mt-10" : ""}
                >
                  <Typography
                    style={{ fontSize: 14, fontFamily: "Montserrat" }}
                  >
                    {description.description}
                  </Typography>
                  <Typography
                    className="mt-2 text-blue-300"
                    style={{ fontSize: 14, fontFamily: "Montserrat" }}
                  >
                    <u>Read More Here</u>
                  </Typography>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
