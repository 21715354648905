import React from "react";
import { HeaderPage } from "../portfolio/HeaderPage";
import { Slide, Typography } from "@mui/material";
import { convertImagePathToUrl } from "../../utils";
import { TrendHamburger } from "./TrendHamburger";
import { TrendButtons } from "./TrendButtons";

export const TrendIntroduction = ({ trendHero, trendAbout }) => {
  const menu2 = [
    { id: 0, title: "About" },
    { id: 1, title: "TREND Team" },
    { id: 2, title: "TREND Themes" },
    { id: 3, title: "TREND Trends" },
    { id: 4, title: "TREND Treats" },
  ];

  const trendHeadbtn = [
    {
      id: 0,
      title: "Contact Us",
    },
  ];

  if (trendHero.length === 0) {
    return;
  }

  const trendButtonsFromJson = trendHero.details?.flatMap(
    (detail) =>
      detail.buttons?.map((button) => ({
        id: button.button_id,
        title: button.label,
        action: button.action,
      })) || []
  );

  return (
    <>
      <div id="hero" className="z-0 trendBgImg1">
        <div
          className="hidden lg:block"
          style={{ position: "", top: 0, zIndex: 50 }}
        >
          <HeaderPage menu1={menu2} headbutton1={trendHeadbtn} />
        </div>
        <div className="xl:hidden">
          <TrendHamburger />
        </div>

        {/* Hero */}
        <div
          className="flex items-center justify-between mx-auto text-white 2xl:flex-row"
          style={{ maxWidth: "80%", height: "100vh" }}
        >
          <div className="w-1/2">
            <Typography
              className="mt-[5rem]"
              style={{
                fontSize: 48,
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              {trendHero?.section_heading}
            </Typography>
            {trendHero?.details?.map((detail) => (
              <>
                {detail?.singleArray?.map((det, index) => (
                  <>
                    {det?.key === "sub-heading" && (
                      <Typography
                        key={index}
                        className="mt-5"
                        style={{
                          fontSize: 25,
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {det?.sub_heading}
                      </Typography>
                    )}
                    {det?.key === "desc" && (
                      <Typography
                        key={index + "desc"}
                        className="mx-auto my-5"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {det?.description}
                      </Typography>
                    )}
                  </>
                ))}
              </>
            ))}

            <div className="pb-20">
              <TrendButtons Tbutton1={trendButtonsFromJson} />
            </div>
          </div>
          {/*  */}
          <div className="hidden py-20 lg:block md:w-1/2 2xl:px-20">
            {trendHero.details?.[0].assets?.[0].videos?.map((video, index) => (
              <iframe
                key={index}
                // className="w-full my-4 rounded-lg h-64 md:h-80 md:w-[570px] lg:h-96"
                className="w-[568px] my-4 rounded-lg h-96"
                src={video?.video_url}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ))}
          </div>
        </div>
      </div>

      {/* About */}
      <div id="About" className="z-0 flex flex-row py-20 trendBgImg2">
        <div
          className="flex justify-between mx-auto 2xl:flex-row"
          style={{ maxWidth: "80%" }}
        >
          {trendAbout?.details?.map((detail) => (
            <>
              {detail?.singleArray?.map((det) => (
                <>
                  {det?.key === "image" && (
                    <div className="hidden xl:w-1/2 lg:block">
                      <img
                        className="mt-[12rem]"
                        src={convertImagePathToUrl(det?.image_url)}
                        alt="Trend"
                      />
                    </div>
                  )}
                </>
              ))}

              <div className="px-10 text-white xl:w-1/2 mt-[5rem]">
                <Typography
                  className=""
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                  }}
                >
                  {trendAbout?.section_heading}
                </Typography>
                {detail?.singleArray?.map((det, ind) => (
                  <>
                    {det?.key === "sub-heading" && (
                      <Typography
                        key={ind}
                        className="mt-5"
                        style={{
                          fontSize: 36,
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {det?.sub_heading}
                      </Typography>
                    )}
                    {det?.key === "desc" &&
                      det?.description_position !== 3 &&
                      det?.description_position !== 4 &&
                      det?.description_position !== 5 && ( // Exclude positions 3, 4, and 5
                        <Typography
                          key={ind + "desc"}
                          className="mx-auto my-5"
                          style={{ fontSize: 14, fontFamily: "Montserrat" }}
                        >
                          {det?.description}
                        </Typography>
                      )}
                  </>
                ))}

                {/* Ordered list for descriptions with position 3 and 4 */}
                <ol className="pl-5 list-decimal">
                  {detail?.singleArray
                    ?.filter(
                      (det) =>
                        det?.key === "desc" &&
                        (det?.description_position === 3 ||
                          det?.description_position === 4)
                    )
                    .sort(
                      (a, b) => a.description_position - b.description_position
                    )
                    .map((det, index) => (
                      <li key={index} className="my-2">
                        <Typography
                          style={{
                            fontSize: 14,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.description}
                        </Typography>
                      </li>
                    ))}
                </ol>

                {/* Separate rendering for description with position 5 */}
                {detail?.singleArray?.map((det, ind) => (
                  <>
                    {det?.key === "desc" && det?.description_position === 5 && (
                      <Typography
                        key={ind + "desc-5"}
                        className="pb-20 mx-auto my-5"
                        style={{ fontSize: 14, fontFamily: "Montserrat" }}
                      >
                        {det?.description}
                      </Typography>
                    )}
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
