import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";
import whiteArrowLeft from "../../assets/images/WhiteArrowLeft.png";

export const TeamLoop1 = ({ Loop1 }) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Extract image, sub-heading, and descriptions from the Loop1 object
  const image = Loop1.find((item) => item.key === "image");
  const subHeading = Loop1.find((item) => item.key === "sub-heading");
  const descriptions = Loop1.filter((item) => item.key === "desc");

  // Find the smallest and largest description_id
  const description1 =
    descriptions.length > 0
      ? descriptions.reduce((min, desc) =>
          desc.description_id < min.description_id ? desc : min
        )
      : null;
  const description2 =
    descriptions.length > 0
      ? descriptions.reduce((max, desc) =>
          desc.description_id > max.description_id ? desc : max
        )
      : null;

  return (
    <div
      style={{
        backgroundColor: "#EBEEF3",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <div
        className="flex justify-between"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        {/* Image 1 */}
        <div className="flex justify-end hidden lg:block" style={{ flex: 1 }}>
          {image && (
            <img
              src={whiteArrowLeft}
              alt="Team Image 1"
              className="ml-auto leftArrowStyle h-[700px]"
            />
          )}
        </div>

        {/* Content */}
        <div
          className="h-[700px] flex flex-col items-center bg-white"
          style={{ flex: 2 }}
        >
          <div className="py-24 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
            {/* Conditionally render title or new div based on sub_heading_position */}
            {subHeading && (
              <>
                {subHeading.sub_heading_position === 1 && (
                  <Typography
                    className="pb-8"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 36,
                      fontWeight: 400,
                    }}
                  >
                    TREND Team
                  </Typography>
                )}
                {subHeading.sub_heading_position === 3 && (
                  <div
                    className="pb-16"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {/* Your custom content or placeholder */}
                  </div>
                )}
              </>
            )}
            {/* Sub-heading */}
            {subHeading && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: 600,
                  fontWeight: "bolder",
                }}
              >
                {subHeading.sub_heading}
              </Typography>
            )}
            {/* Description 1 (Smallest ID) */}
            {description1 && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {description1.description}
              </Typography>
            )}
            {/* Description 2 (Largest ID) */}
            {description2 && (
              <Typography
                className="pb-8"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {description2.description}
              </Typography>
            )}
          </div>
        </div>

        {/* Image 2 */}
        <div className="hidden bg-white lg:block" style={{ flex: 1 }}>
          {image && (
            <img
              src={convertImagePathToUrl(image.image_url)}
              alt="Team Image 2"
              className="h-full ml-auto leftArrowStyle"
            />
          )}
        </div>
      </div>
    </div>
  );
};
