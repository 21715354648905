import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../../assets/images/Polygon.svg";

import React from "react";
import { Typography } from "@mui/material";

export const PubCrousal = ({ subHeadings }) => {
  // console.log(subHeadings);
  // const mobPolygon = [
  //   {
  //     id: 0,
  //     img: img1,
  //     title: "Neuroscience of Trauma and Russian Invasion of Ukraine",
  //     description:
  //       "Nature Human Behaviour, 2022 Media: The Daily Express, Tech Register, Daily Star Post",

  //     link: "https://www.nature.com/articles/s41562-022-01344-4",
  //   },
  //   {
  //     id: 1,
  //     img: img1,
  //     title: "Nuclear Receptor Signaling and Epigenetic Inheritance",
  //     description: "EMBO Journal, 2020 Media: IFLS, Technology News",
  //     link: "",
  //   },
  //   {
  //     id: 2,
  //     img: img1,
  //     title: "TDP-43 Depletion and Synapse Loss",
  //     description: "Neuron, 2017 Media: Science Daily",
  //     link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5519492/",
  //   },
  //   {
  //     id: 3,
  //     img: img1,
  //     title: "Worsening of Psychiatric Conditions During COVID-19",
  //     description:
  //       "Frontiers in Psychiatry, 2020 Media: Polytika Poland, 20minuten, MSN Germany",
  //     link: "https://www.frontiersin.org/journals/psychiatry/articles/10.3389/fpsyt.2020.581426/full",
  //   },
  //   {
  //     id: 4,
  //     img: img1,
  //     title: "Metabolic Disorders in ALS/FTLD",
  //     description: "Molecular Neurodegeneration, 2018",
  //     link: "https://molecularneurodegeneration.biomedcentral.com/articles/10.1186/s13024-018-0294-0",
  //   },
  //   {
  //     id: 5,
  //     img: img1,
  //     title: "Sperm RNAs and Transgenerational Inheritance",
  //     description: "Nature Neuroscience, 2014 Media: Science Daily, IFLS, BBC",

  //     link: "https://www.nature.com/articles/nn.3695",
  //   },
  //   {
  //     id: 6,
  //     img: img1,
  //     title: "Parental Exposure Impact on Offspring Health",
  //     description: "Trends in Genetics, 2021 Media: The Scientist",
  //     link: "",
  //   },
  //   {
  //     id: 7,
  //     img: img1,
  //     title: "Memory Decline and miR-183/96/182 Biogenesis",
  //     description: "Molecular Neurobiology, 2018",
  //     link: "https://link.springer.com/article/10.1007/s12035-018-1314-3",
  //   },
  //   {
  //     id: 8,
  //     img: img1,
  //     title: "ALS Onset and Diabetes Mellitus",
  //     description: "European Journal of Neurology, 2010",
  //     link: "https://onlinelibrary.wiley.com/doi/10.1111/j.1468-1331.2009.02923.x",
  //   },
  // ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="py-10 ">
      <Slider {...settings}>
        {subHeadings.map((value) => (
          <a
            key={value.id}
            href={value.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className="relative inline-block 2xl:my-10 w-80 md:w-1/2 mobPolygon ">
              <Typography
                className="mx-10 mt-20 text-center text-white sm:mt-36 sm:mx-5"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 20,
                  fontWeight: "bolder",
                }}
              >
                {value.sub_heading}
              </Typography>
              <Typography
                className="mx-10 mt-5 text-center text-white sm:mx-5 sm:mt-5"
                style={{ fontFamily: "Montserrat", fontSize: 16 }}
              >
                {value.sub_heading_desc}
              </Typography>
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};
