import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import WhiteArrowRight from "../../assets/images/WhiteArrowRight.png";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.png";
import GrayArrowLeft from "../../assets/images/GrayArrowLeft.png";
import TrendTreat1 from "../../assets/images/TrendTreat1.png";
import HumantarianImg3 from "../../assets/images/HumantarianImg3.png";
import TrendTreat2 from "../../assets/images/TrendTreat2.png";
import { TrendSeminars } from "./TrendSeminars";
import { convertImagePathToUrl } from "../../utils";

import "aos/dist/aos.css";

export const TrendTreats = ({ trendTreats = [], trendSeminar }) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (trendTreats?.length === 0 && !trendSeminar) return;

  // Extract singleArray from trendTreats objects with default empty arrays
  const singleArray = trendTreats[0]?.singleArray || [];
  const secondSingleArray = trendTreats[1]?.singleArray || [];

  return (
    <div>
      {/* Detail 0 */}
      <div id="TREND Treats">
        <div
          className="h-[700px] flex justify-between"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <div className="flex justify-end hidden lg:block" style={{ flex: 1 }}>
            {/* Render image from singleArray */}
            {singleArray.map((item) =>
              item.key === "image" ? (
                <img
                  key={item.asset_id}
                  src={convertImagePathToUrl(item.image_url)}
                  className="w-[434px] h-[641px] object-contain pt-16"
                />
              ) : null
            )}
          </div>
          <div
            className="flex flex-col items-center bg-white"
            style={{ flex: 3 }}
          >
            <div className="py-10 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
              <Typography
                className="py-10"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 36,
                  fontWeight: 400,
                }}
              >
                TREND Treats
              </Typography>
              <Typography
                className="pb-10 "
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 24,
                  fontWeight: 400,
                }}
              >
                TREND Socials
              </Typography>

              {/* Render sub-headings and descriptions from singleArray */}
              {singleArray.map((item) => {
                switch (item.key) {
                  case "sub-heading":
                    return (
                      <Typography
                        key={item.sub_heading_id}
                        className="pb-10"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: item.sub_heading_position === 1 ? 16 : 16,
                          fontWeight: 600,
                        }}
                      >
                        {item.sub_heading}
                      </Typography>
                    );
                  case "desc":
                    return (
                      <Typography
                        key={item.description_id}
                        className="pb-10"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {item.description}
                      </Typography>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
          <div
            className="hidden pr-10 lg:block"
            style={{ flex: 1, backgroundColor: "#EBEEF3" }}
          >
            <img
              src={WhiteArrowRight}
              className="h-full mr-auto leftArrowStyle"
            />
          </div>
        </div>
      </div>

      {/* Detail 1 */}
      <div className="TrendTreatBg" style={{ overflowX: "hidden" }}>
        <div
          className="h-[700px] flex justify-between"
          data-aos="fade-left"
          data-aos-delay="300"
        >
          <div
            className="hidden pl-10 lg:block"
            style={{ flex: 1, backgroundColor: "" }}
          >
            <img
              src={GrayArrowLeft}
              className="h-full ml-auto leftArrowStyle"
              style={{ backgroundColor: "" }}
            />
          </div>
          <div
            className="flex flex-col items-center"
            style={{ flex: 3, backgroundColor: "#EBEEF3" }}
          >
            <div className="py-32 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
              {/* Render sub-headings and descriptions from secondSingleArray */}
              {secondSingleArray.map((item) => {
                switch (item.key) {
                  case "sub-heading":
                    return (
                      <Typography
                        key={item.sub_heading_id}
                        className="py-10"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: item.sub_heading_position === 1 ? 16 : 16,
                          fontWeight: 600,
                        }}
                      >
                        {item.sub_heading}
                      </Typography>
                    );
                  case "desc":
                    return (
                      <Typography
                        key={item.description_id}
                        className="pb-10"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {item.description}
                      </Typography>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
          <div
            className="hidden lg:block xl:pt-0 pt-44"
            style={{ flex: 1, backgroundColor: "#EBEEF3" }}
          >
            <img
              src={TrendTreat1}
              className="object-contain w-[434px] h-[641px] pt-10"
            />
          </div>
        </div>
        <div>
          <TrendSeminars trendSeminar={trendSeminar} />
        </div>
      </div>
    </div>
  );
};
